
const VocabList = () => {
    return (
        <div className="page">
            <h1>
                Словарь
            </h1>
        </div>
    )
}

export default VocabList